.App {
  text-align: center;
  font-family: '"Source Code Pro", monospace';
}

.App-logo {
  height: 6vmin;
  pointer-events: none;
}

.App-header {
  background-color: #d2dceb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.button {
  border-radius: 10px;
    padding: 10px 24px;
    font-size: 20px;
    background-color: #b7ddc6;
}

.App-header.h1 {
  font-family: 'Source Code Pro", monospace';
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
